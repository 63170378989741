import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AccountForm from "./AccountForm";
import "./index.scss";
import { Breadcrumb, Typography, Divider } from "antd";

const { Title, Text } = Typography;

export default function Account() {
  const { item: accountData } = useSelector(({account}) => account);
  const [ formData, setFormData ] = useState(accountData || false);

  useEffect(() => {
    if (accountData && !formData) {
      setFormData(accountData);
    }
  }, [formData, accountData])

  return (
    <div>
      <Breadcrumb
        style={{
          marginTop: 20,
          fontSize: '1rem',
        }}
        items={[
          {
            title: 'Account',
          },
          {
            title: 'Settings',
          },
        ]}
      />
      <div>
        <Title level={2} style={{ marginBottom: 5 }}>Account Settings</Title>
        <Text type="secondary" style={{ fontSize: 16 }}>
          Change your account details in the form below then click on the submit button to save the changes.
        </Text>
      </div>
      <Divider />
      {formData.firstName && (
        <AccountForm data={formData} />
      )}
    </div>
  )
}