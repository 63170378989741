function Page404() {
  return <div>
    <h1>404 not found</h1>
    <ul>
      <li>
        <a href="/">Back</a>
      </li>
    </ul>
  </div>
}

export default Page404;