import {
  SET_ORDER_FETCHING,
  SET_ORDER,
  SET_TRANSACTION_DATA,
  SET_TRANSACTION_FETCHING_LIST,
} from '../ActionTypes';
import request from "../util/requestWrapper";

export const getTransactionsList = () => {
  return async (dispatch) => {
    dispatch({ type: SET_TRANSACTION_FETCHING_LIST, payload: true });
    try {
      const data = await request({ method: 'GET', url: `/api/transactions` });
      if (data) {
        dispatch({ type: SET_TRANSACTION_DATA, payload: data});
        dispatch({ type: SET_TRANSACTION_FETCHING_LIST, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_TRANSACTION_FETCHING_LIST, payload: false });
      throw new Error(error.message);
    }
  };
};

export const createPaypalOrder = ({ body }) => {
  return async (dispatch) => {
    try {
      return await request({ method: 'POST', url: `/api/orders`, body: body });
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const capturePaypalOrder = ({ orderId }) => {
  return async (dispatch) => {
    dispatch({ type: SET_ORDER_FETCHING, payload: true });
    try {
      const data = await request({ method: 'POST', url: `/api/orders/${orderId}/capture` });
      if (data) {
        dispatch({ type: SET_ORDER, payload: data});
        dispatch({ type: SET_ORDER_FETCHING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_ORDER_FETCHING, payload: false });
      throw new Error(error.message);
    }
  };
};

export const getPaypalToken = () => {
  return async (dispatch) => {
    try {
      return await request({ method: 'get', url: `/api/orders/token` });
    } catch (error) {
      throw new Error(error.message);
    }
  };
};