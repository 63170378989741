function Connect() {
  return <div>
    <h1>Connection page</h1>
    <ul>
      <li>
        <a href="/">Back</a>
      </li>
    </ul>
  </div>
}

export default Connect;