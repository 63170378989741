import React from 'react';
import {
    DollarOutlined, WalletOutlined, 
    SettingOutlined, LogoutOutlined, HomeOutlined,
 } from '@ant-design/icons';
import { Layout, Menu, Typography, Avatar, theme } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from "../img/mesh_logo.png";
import KeycloakUserService from "../Services/KeycloakUserService";
import {setAtAuth} from "../redux/actions";
import "./index.scss";

const { Sider } = Layout;
const { Link } = Typography;
const items = [
    {
        key: 'sub1',
        label: 'Dashboard',
        type: 'group',
        children: [
          {
            key: '/',
            label: 'Home',
            icon: <HomeOutlined />,
          },
          {
            key: '/payments',
            label: 'Payments',
            icon: <DollarOutlined />,
          },
          {
            key: '/billingplan',
            label: 'Billing Plans',
            icon: <WalletOutlined />,
          },
        ],
    },
    {
        key: 'sub2',
        label: 'Account',
        type: 'group',
        children: [
          {
            key: '/account',
            label: 'Settings',
            icon: <SettingOutlined />,
          },
          {
            key: 'logout',
            label: 'Logout',
            icon: <LogoutOutlined />,
          },
        ],
    },
]

export default function SideBar() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {item: accountData} = useSelector(({account}) => account);
    const onClick = (e) => {
        // console.log('click ', e.key);
        if(e.key === 'logout') {
            KeycloakUserService.doLogout();
            dispatch(setAtAuth(false));
        } else history.push(e.key);
    };
    // console.log('history: ', history);
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    return (
        <Sider
            breakpoint="xl"
            collapsedWidth="0"
            style={{
                background: colorBgContainer,
            }}
            onBreakpoint={(broken) => {
                // console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                // console.log(collapsed, type);
            }}
        >
            <Link
                href="/"
                style={{
                marginTop: 10,
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                <img alt="logo" width={120} src={logo}/>
            </Link>
            <Menu 
                mode="inline" 
                onClick={onClick}
                // defaultSelectedKeys={['/']} 
                defaultSelectedKeys={[history.location.pathname]} 
                items={items} 
                style={{
                    border: 'none',
                }}
            />
            <div className='avatar'>
                <Avatar style={{ color: '#fff', backgroundColor: 'rgb(93, 135, 255)' }}>
                    { accountData && `${accountData.firstName.substring(0,1)} ${accountData.lastName.substring(0,1)}`}
                </Avatar>
                <h4 style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 5,
                }}>
                    {accountData && accountData.firstName}
                    <div style={{
                        userSelect: 'none',
                        width: '1em',
                        height: '1em',
                        display: 'inline-block',
                        fill: 'rgb(93, 135, 255)',
                        flexShrink: 0,
                        fontSize: '1.5rem',
                        marginLeft: 10,
                        paddingBottom: 30,
                        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1)',
                    }}>
                        <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AlbumOutlinedIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-12.5c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 5.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>
                    </div>
                </h4>
            </div>
        </Sider>
    );
};