import "./index.scss";

export default function ConnectSvg() {
    return (
        <div class="wifi-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 352">
                <title>wifi</title>
                <g id="wifi">
                    <g id="mountain">
                    <path d="M108.76,215.11V205.06c3.59-3.45,6.1-6.51,7.12-8.93,6.16,0,8.21,5.93,13.74,9.23q.88-.57,1.77-1.27v11H108.76Z" style={{ fill:"#abd9ef"}}/>
                    <path id="Shape_18_copy" data-name="Shape 18 copy" d="M209.4,256.42H11c26.51,0,98-44.31,104.7-60.4l1.79,12.79s10.19,1,18.62-10.18c7-9.27,14.06-24.63,14.06-24.63l59.22,82.42Z" style={{fill:"#8ab8d4"}}/>
                    <path id="Shape_18_copy_7" data-name="Shape 18 copy 7" d="M164.18,256.36H243s-7.09,2-23.31-4.86c-19.89-8.38-54-77.45-69.51-77.45Z" style={{fill:"#abd9ef"}}/>
                    </g>
                    <g id="tree">
                    <circle id="Shape_18_copy_8" data-name="Shape 18 copy 8" cx="46" cy="208.11" r="34" style={{fill:"#069372"}}/>
                    <path id="Shape_18_copy_13" data-name="Shape 18 copy 13" d="M12,208.1A34.05,34.05,0,0,1,46,174v68.21A34.05,34.05,0,0,1,12,208.1Z" style={{fill:"#0eb991"}}/>
                    <path id="Shape_18_copy_9" data-name="Shape 18 copy 9" d="M54.12,215.33A4.45,4.45,0,0,0,51,216.64a4.5,4.5,0,0,0-1.14,4.4,4.5,4.5,0,0,0,1.22.17,4.45,4.45,0,0,0,3.16-1.31,4.49,4.49,0,0,0,1.14-4.39,4.49,4.49,0,0,0-1.22-.17m-4-14a4.5,4.5,0,0,0-1.22.17,4.5,4.5,0,0,0,1.14,4.4,4.44,4.44,0,0,0,3.16,1.31,4.5,4.5,0,0,0,1.22-.17,4.5,4.5,0,0,0-1.14-4.4,4.45,4.45,0,0,0-3.16-1.31M56,227a4.5,4.5,0,0,0-1.22.17,4.48,4.48,0,0,0,5.52,5.54,4.49,4.49,0,0,0-1.14-4.4A4.45,4.45,0,0,0,56,227m4-16.65a4.48,4.48,0,0,0-1.22.17A4.48,4.48,0,0,0,64.29,216,4.48,4.48,0,0,0,60,210.3m4.42-11.67a4.48,4.48,0,0,0-4.3,5.71,4.48,4.48,0,0,0,5.52-5.54,4.49,4.49,0,0,0-1.22-.17M68.88,222a4.44,4.44,0,0,0-3.16,1.32,4.5,4.5,0,0,0-1.14,4.39,4.5,4.5,0,0,0,1.23.17A4.44,4.44,0,0,0,69,226.54a4.5,4.5,0,0,0,1.14-4.4,4.49,4.49,0,0,0-1.22-.17m3.63-11.67a4.48,4.48,0,0,0-1.22.17A4.48,4.48,0,0,0,76.81,216a4.48,4.48,0,0,0-4.3-5.71" style={{fill:"#058d71"}}/>
                    <path id="Shape_18_copy_12" data-name="Shape 18 copy 12" d="M23.14,209.41a4.48,4.48,0,0,0-4.3,5.71,4.5,4.5,0,0,0,1.22.17A4.45,4.45,0,0,0,23.22,214a4.5,4.5,0,0,0,1.14-4.4,4.49,4.49,0,0,0-1.22-.17m2.4-19.74a4.49,4.49,0,0,0-1.22.17,4.48,4.48,0,0,0,5.52,5.54,4.5,4.5,0,0,0-1.14-4.4,4.45,4.45,0,0,0-3.17-1.31m7.65,27.42a4.47,4.47,0,0,0-1.22.17,4.49,4.49,0,0,0,1.14,4.4A4.44,4.44,0,0,0,36.27,223a4.49,4.49,0,0,0,1.22-.17,4.48,4.48,0,0,0-4.3-5.71m-5.82-16.6a4.49,4.49,0,0,0-1.22.17,4.48,4.48,0,0,0,5.52,5.54,4.48,4.48,0,0,0-4.3-5.71m10.19-18.85a4.45,4.45,0,0,0-3.16,1.31,4.49,4.49,0,0,0-1.14,4.4,4.51,4.51,0,0,0,1.22.17,4.45,4.45,0,0,0,3.16-1.31,4.51,4.51,0,0,0,1.14-4.4,4.48,4.48,0,0,0-1.22-.17m2.73,22.84a4.45,4.45,0,0,0-3.16,1.31,4.5,4.5,0,0,0-1.14,4.4,4.5,4.5,0,0,0,1.22.17A4.45,4.45,0,0,0,40.38,209a4.5,4.5,0,0,0,1.14-4.4,4.48,4.48,0,0,0-1.23-.17M39.45,192a4.5,4.5,0,0,0-1.22.17,4.48,4.48,0,0,0,5.52,5.54,4.48,4.48,0,0,0-4.3-5.71" style={{fill:"#0bac8a"}}/>
                    <path id="Shape_18_copy_10" data-name="Shape 18 copy 10" d="M40,255.88l6-37,6,37H40" style={{fill:"#00766c"}}/>
                    </g>
                    <g id="beamz">
                    <path d="M120.83,133.78a21.53,21.53,0,1,1,21.53-21.53A21.55,21.55,0,0,1,120.83,133.78Zm0-42.27a20.74,20.74,0,1,0,20.74,20.74A20.77,20.77,0,0,0,120.83,91.51Z" style={{fill:"none"}}/>
                    <path d="M120.83,151.76a39.51,39.51,0,1,1,39.51-39.51A39.56,39.56,0,0,1,120.83,151.76Zm0-77.59a38.08,38.08,0,1,0,38.08,38.08A38.12,38.12,0,0,0,120.83,74.17Z" style={{fill:"none"}}/>
                    <path d="M120.83,169.5a57.25,57.25,0,1,1,57.25-57.25A57.31,57.31,0,0,1,120.83,169.5Zm0-112.42A55.17,55.17,0,1,0,176,112.25,55.23,55.23,0,0,0,120.83,57.08Z" style={{fill:"none"}}/>
                    <path d="M120.83,195.42A83.17,83.17,0,1,1,204,112.25,83.26,83.26,0,0,1,120.83,195.42Zm0-163.32A80.15,80.15,0,1,0,201,112.25,80.24,80.24,0,0,0,120.83,32.1Z" style={{fill:"none"}}/>
                    </g>
                    <g id="wifi-signal">
                    <path d="M129.78,89.52a26.36,26.36,0,0,1,10,6.33l-6.33,6.35a17.5,17.5,0,0,0-6.67-4.27,17.74,17.74,0,0,0-18.52,4.2l-6.3-6.33A26.73,26.73,0,0,1,129.78,89.52Z" style={{fill:"none"}}/>
                    <path d="M135.84,72.69a43.91,43.91,0,0,1,16.48,10.46L146,89.56A35.27,35.27,0,0,0,132.57,81a35.93,35.93,0,0,0-37.18,8.26L89.34,83.2A44.69,44.69,0,0,1,135.84,72.69Z" style={{fill:"none"}}/>
                    <path d="M138.95,64.36A53.45,53.45,0,0,0,83.13,77.12l-6.3-6.33A62.44,62.44,0,0,1,142,55.94a61.41,61.41,0,0,1,22.84,14.41l-6.3,6.37A52.54,52.54,0,0,0,138.95,64.36Z" style={{fill:"none"}}/>
                    </g>
                    <path id="tower" d="M126.13,125.76,150.83,290l-5.37.61-.86-5.71L120.83,271,97.06,284.93l-.86,5.72L90.83,290l24.7-164.27a13.74,13.74,0,1,1,10.6,0Zm-5.3,13.32-1.31.77,1.31.77,1.31-.77Zm.07-4.31-.07,0-.07,0,.07,0Zm-4.49,21.39-.3,2,4.71,2.77,4.71-2.76-.3-2-4.41-2.59Zm.78-4.72-.06,0v.08Zm7.35,0v-.08l-.06,0Zm-3.71-2.17,3.11-1.82-.13-.87-3-1.75-3,1.75-.13.87Zm5.3,12.75-1.66,1,2,1.16Zm1.8,12-3.47,2,4.14,2.42Zm2.6,17.32-6.07,3.56,7.24,4.25Zm3,20-9.08,5.32L135.3,223ZM136.95,234l-12.48,7.32L139.36,250Zm7,46.25-.24-1.62-2.9-19.32-16.29,9.55ZM98,278.63l-.24,1.62,19.43-11.39-16.29-9.55Zm3.5-23.3v.06l19.33,11.33,19.33-11.33v-.06l-.09-.61-19.23-11.27L101.6,254.73Zm.8-5.29,14.89-8.73L104.71,234Zm3-20,15.53,9.1,15.53-9.1L136,227.7l-15.17-8.89-15.17,8.89Zm1.06-7.06,10.83-6.35-9.08-5.32Zm2.34-15.58,12.12,7.11,12.12-7.11-.54-3.59-11.58-6.79-11.58,6.79Zm1.24-8.26,7.24-4.25-6.07-3.56Zm2.14-14.2-.37,2.48,9.12,5.34,9.12-5.34-.37-2.48-.27-1.79-8.48-5-8.48,5Zm1-6.46,4.14-2.42-3.47-2Zm1.35-8.95-.09.57,6.51,3.82,6.51-3.82-.08-.57-.1-.68-6.33-3.71-6.33,3.71Zm.81-5.36,2-1.16-1.66-1Zm5.11-34,.51.3.51-.3-.51-3.35h0Z" style={{fill:"#6b8293"}}/>
                    <rect width="248" height="352" style={{fill:"none"}}/>
                </g>
            </svg>

        </div>
    )
};