import KeycloakUserService from "../Services/KeycloakUserService";
import logo from "../img/mesh_logo.png";
import {Button} from "@mui/material";

const Welcome = () => (
  <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
    <div style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
        <img alt="logo" style={{ width: "200px" }} src={logo} />
        <p className="lead">Please authenticate yourself!</p>
        <p>
            <Button variant="outlined" onClick={() => KeycloakUserService.doLogin()}>Login</Button>
        </p>
    </div>
  </div>
)

export default Welcome