import React from "react";
import { Table } from 'antd';
import moment from "moment";

export default function TransactionsTable({ items }){
  // console.log("items: ", items);
  const data = items && items.length > 0 ? items.map(item => { return {
    key: item.id,
    date: moment(item.createdAt).format("YYYY-MM-DD LT"),
    amount: item.amount,
    approved: item.approvedByPaypal ? "YES" : "NO"
  }}) : [];
  // console.log("data: ", data);
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: 'Approved',
      dataIndex: 'approved',
      key: 'approved',
      align: 'center',
    },
  ];
  return (
    <div>
      <Table dataSource={data} columns={columns} bordered />
    </div>
  )
}