import {
  SET_BILLING_PLAN_DATA,
  SET_BILLING_PLAN_FETCHING_LIST,
} from '../ActionTypes';
import request from "../util/requestWrapper";

export const getBillingPlansList = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_BILLING_PLAN_FETCHING_LIST, payload: true });
    try {
      const data = await request({ method: 'GET', url: `/api/billingplans` });
      if (data) {
        dispatch({ type: SET_BILLING_PLAN_DATA, payload: data});
        dispatch({ type: SET_BILLING_PLAN_FETCHING_LIST, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_BILLING_PLAN_FETCHING_LIST, payload: false });
      throw new Error(error.message);
    }
  };
};

export const changeBillingPlan = ({ body }) => {
  return async (dispatch) => {
    dispatch({ type: SET_BILLING_PLAN_FETCHING_LIST, payload: true });
    try {
      const data = await request({ method: 'PATCH', url: `/api/me/billingplan`, body: body });
      if (data) {
        // dispatch({ type: SET_BILLING_PLAN_DATA, payload: data});
        dispatch({ type: SET_BILLING_PLAN_FETCHING_LIST, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_BILLING_PLAN_FETCHING_LIST, payload: false });
      throw new Error(error.message);
    }
  };
};
