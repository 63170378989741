import {
  SET_BILLING_PLAN_DATA,
  SET_BILLING_PLAN_FETCHING_LIST, SET_BILLING_PLAN_FETCHING
} from '../ActionTypes';

const INIT_STATE = {
  items: null,
  isFetchingItem: false,
  isFetching: false,
};

const state = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BILLING_PLAN_DATA: {
      return {
        ...state,
        items: action.payload
      };
    }
    case SET_BILLING_PLAN_FETCHING_LIST: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }
    case SET_BILLING_PLAN_FETCHING: {
      return {
        ...state,
        isFetchingItem: action.payload,
      };
    }
    default:
      return state;
  }
};

export default state;
