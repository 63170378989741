import React, {useEffect, useState} from "react";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {
  capturePaypalOrder,
  createPaypalOrder, getAccountData,
  getPaypalToken, /*createPaypalOrder,*/
  getTransactionsList
} from "../../redux/actions";
import {useDispatch} from "react-redux";
import {confirm} from "../../Components/Confirm/confirm";
import {paypalClientId} from "../../redux/util/config";
import { Spin } from 'antd';

const initialOptions = {
  // "client-id": "AfEUx7MpPGew6mqeL3LuutLKgfMrgxS_udKcwSnH5Bg5VUBIx4oFfUMYT2jVzZc4rITZqcI6ph2KoOAp",
  "client-id": paypalClientId,
  currency: "USD",
  intent: "capture",
  // "data-client-token": "AfEUx7MpPGew6mqeL3LuutLKgfMrgxS_udKcwSnH5Bg5VUBIx4oFfUMYT2jVzZc4rITZqcI6ph2KoOAp",
};

export default function PaypalButtonsServer(){
  const dispatch = useDispatch();
  const [ ppData, setPpData ] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!ppData) {
      setLoading(true);
      dispatch(getPaypalToken())
        .then(res => {
          setPpData({
            ...initialOptions,
            ...res
          });
          setLoading(false);
        });
    }
  }, [ppData, dispatch]);

  return (
    <div style={{ maxWidth: "250px", width: "100%" }}>
      { loading && <Spin size="large" />}
      {ppData && (
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            style={{ color:  'blue', shape:  'pill', label:  'pay', height: 40 }}
            createOrder={(data, actions) => {
              return dispatch(createPaypalOrder({})).then(res => {
                console.log(res)
                return res.id
              })
              /*return fetch("http://localhost:3001/api/orders", {
                method: "post",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
                // use the "body" param to optionally pass additional order information
                // like product ids or amount
              })
                .then((response) => {
                  console.log(response);
                  return response.json()
                })
                .then((order) => {
                  console.log(order)
                  return order.id
                });*/
            }}
            onApprove={(data, actions) => {
              return dispatch(capturePaypalOrder({ orderId: data.orderID }))
                .then(() => dispatch(getTransactionsList()))
                .then(() => {
                  confirm({
                    confirmActionText: "Ok", // optional
                    confirmAction: () => {}, // optional
                    cancelAction: () => {}, // optional
                    text: "Successful replenishment", // optional
                  })
                })
                .then(() => dispatch(getAccountData()))
            }}
          />
        </PayPalScriptProvider>
      )}
    </div>
  )
}