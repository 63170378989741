import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import "./confirm.scss";
import { Button } from 'antd';

/*
USAGE
import { confirm } from "../Modals/Confirm";
showDeleteModal = () => {
 confirm({
   confirmActionText: "Delete", // optional
   cancelActionText: "Cancel", // optional
   confirmAction: () => {}, // optional
   cancelAction: () => {}, // optional
   text: "Are you sure you want to delete?", // optional
 });
};
*/

function removeElementReconfirm() {
  const target = document.getElementById("react-confirm-alert");
  document.body.children[1].classList.remove("react-confirm-alert-blur");
  unmountComponentAtNode(target);
  target.parentNode.removeChild(target);
}

function createElementReconfirm(properties) {
  let div = document.getElementById("react-confirm-alert");
  if (div) {
    // Rerender - the mounted ReactConfirmAlert
    render(<ConfirmModal {...properties} />, div);
  } else {
    // Mount the ReactConfirmAlert component
    document.body.children[1].classList.add("react-confirm-alert-blur");
    div = document.createElement("div");
    div.id = "react-confirm-alert";
    document.body.appendChild(div);
    render(<ConfirmModal {...properties} />, div);
  }
}

function ConfirmModal({
  confirmAction, cancelAction,
  text, confirmActionText, cancelActionText,
}) {

  const handleClose = () => {
    removeElementReconfirm();
  };

  return (
    <div className="confirm-modal-wrapper">
      <div className="confirm-modal">
        <div className="modal-title">{text || ""}</div>
        <div className="modal-actions">
          {
            confirmAction && (
              <Button 
                type="primary"
                ghost
                className="main-button" 
                onClick={() => { handleClose(); confirmAction(); }}
              >
                {confirmActionText || "Ok"}
              </Button>
            )
          }
          {
            cancelAction && cancelActionText && (
              <Button 
                type="primary" 
                ghost 
                danger 
                className="cancel-button" 
                onClick={() => { handleClose(); cancelAction(); }}
              >
                {cancelActionText || "Cancel"}
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;

export function confirm(properties) {
  createElementReconfirm(properties);
}
