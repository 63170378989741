import Keycloak from "keycloak-js";

const config = "/keycloakTest.json";

// eslint-disable-next-line no-underscore-dangle
const _kc = new Keycloak(config);

const setToLocalStorage = (token = false) => {
    if (token) localStorage.setItem("token", token);
    else localStorage.removeItem("token");
};

/**
 * Initializes Keycloak instance and calls the provided callback
 * function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
// realms/test/protocol/openid-connect/auth
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        // pkceMethod: 'RS256',
    })
      .then((authenticated) => {
          if (!authenticated) {
              console.log("user is not authenticated..!");
          }
          onAuthenticatedCallback();
      })
      .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = (cb) => {
    if (_kc.token) setToLocalStorage(_kc.token);
    else setToLocalStorage();
    cb(_kc.token);
    return !!_kc.token;
};

const updateToken = successCallback => _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = roles => roles.some(role => _kc.hasRealmRole(role));

const KeycloakUserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
};

export default KeycloakUserService;