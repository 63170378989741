import React from "react";
import { Switch, Route } from "react-router-dom";

import MainPage from "../../Pages/MainPage";
import Account from "../../Pages/Account";
import Connect from "../../Pages/Connect";
import Transactions from "../../Pages/Transactions";
import BillingPlan from "../../Pages/BillingPlan";
import Page404 from "../../Pages/Page404";

export default function Router() {
    return (
      <Switch>
        <Route exact path="/account" component={Account} />
        <Route exact path="/connect" component={Connect} />
        <Route exact path="/payments" component={Transactions} />
        <Route exact path="/billingplan" component={BillingPlan} />
        <Route exact path="/" component={MainPage} />
          {/*<Route exact path="/500" compoennt={Page500} />*/}
        <Route path="/404" component={Page404} />
      </Switch>
    );
}