// const apiHost = "http://localhost:3001";
const paypalClientId = "AdJ1OyOSaBaHa3tiqnyhmuhVpzPZfw2myJWXCUP4iQeB31zqYT4DSWMy0WUIltSIytXPMSSq7Ut3UDR3";
const paypalClientIdDev = "AfEUx7MpPGew6mqeL3LuutLKgfMrgxS_udKcwSnH5Bg5VUBIx4oFfUMYT2jVzZc4rITZqcI6ph2KoOAp";
const apiHost = "https://at.billing.meshplusplus.com";
export {
  apiHost,
  paypalClientId,
  paypalClientIdDev,
};

