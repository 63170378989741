import KeycloakUserService from "../Services/KeycloakUserService";
import {useDispatch, useSelector} from "react-redux";
import {setAtAuth} from "../redux/actions";

const RenderOnAnonymous = ({ children }) => {
  const dispatch = useDispatch();
  const {isAuth} = useSelector(({account}) => account);

  const authCB = (kkToken) => {
    if (kkToken) {
      if(!isAuth) dispatch(setAtAuth(true))
    } else {
      if(isAuth) setAtAuth(false)
    }
  }
  return (!KeycloakUserService.isLoggedIn(authCB)) ? children : null;
}

export default RenderOnAnonymous