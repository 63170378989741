import axios from 'axios';
import { apiHost } from './config';
import KeycloakUserService from "../../Services/KeycloakUserService";

async function getToken() {
  if (KeycloakUserService.getToken() === undefined) {
    const myPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
    await myPromise;
    return KeycloakUserService.getToken();
  }
  return KeycloakUserService.getToken();
}

const waitTokenUpdate = ms => new Promise(resolve => setTimeout(resolve, ms));

async function makeRequestArgs(body, headers, method, params, url) {
  const token = await getToken();
  if (!token) return false;
  const allHeaders = {
    ...headers,
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const query = params ? `?${URLSearchParams(params).toString()}` : "";
  const methodName = method.toLowerCase();
  return ["get", "delete"].includes(methodName)
    ? [url + query, { headers: allHeaders }]
    : [url, body, { headers: allHeaders }];
}

const request = async ({ body, headers, method, params, url }) => {
  const client = axios.create({ baseURL: apiHost });
  const methodName = method.toLowerCase();
  let args = await makeRequestArgs(body, headers, method, params, url);
  let response;
  try {
    response = await client[methodName](...args);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      const updated = await KeycloakUserService.updateToken();
      await waitTokenUpdate(3000);
      if (updated) {
        args = await makeRequestArgs(body, headers, method, params, url);
        response = await client[methodName](...args);
        return response.data;
      }
    } else if (error.response.status !== 403) {
      throw new Error(error.response.data);
    }
    if (error.response.status === 403) {
      console.log("!!");
      localStorage.removeItem("token");
      KeycloakUserService.doLogout();
    }
    return null;
  }
};

export default request;
