import {
  SET_TRANSACTION_DATA,
  SET_TRANSACTION_FETCHING_LIST,
  SET_TRANSACTION,
  SET_TRANSACTION_FETCHING,
} from '../ActionTypes';

const INIT_STATE = {
  items: null,
  isFetching: false,
  item: null,
  isFetchingItem: false,
};

const state = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRANSACTION_DATA: {
      return {
        ...state,
        items: action.payload
      };
    }
    case SET_TRANSACTION_FETCHING_LIST: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }
    case SET_TRANSACTION: {
      return {
        ...state,
        item: action.payload
      };
    }
    case SET_TRANSACTION_FETCHING: {
      return {
        ...state,
        isFetchingItem: action.payload,
      };
    }
    default:
      return state;
  }
};

export default state;
