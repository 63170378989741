import { combineReducers } from 'redux';
import BillingPlan from './BillingPlan';
import Account from './Account';
import Transaction from './Transaction';

const func = () =>
  combineReducers({
    billingplan: BillingPlan,
    account: Account,
    transaction: Transaction,
  });
export default func;
