import React from "react";
import {CircularProgress} from "@mui/material";

const calcBalanceHelper = (accountData = false) => {
  if (!accountData) return <CircularProgress />
  let total = 0;
  accountData.billings.forEach(item => {
    if (item.currentAmount > 0) total += item.currentAmount;
  });
  return total.toFixed(2);
}

const trafficBalHelper = (accountData = false) => {
  // console.log("accountData: ", accountData);
  if (!accountData) return <CircularProgress />
  const sorted = [...accountData.billings].sort((a, b) => a.id > b.id ? 1 : -1);
  // console.log("sorted billings: ", sorted);
  const item = sorted[0];
  let cData = 0;
  if(!item) {
    return '0 Gb';
  }
  if (item.billingPlanTypeId === 1 && item.currentData > 0) {
    if (item.currBP.billingUnit === "Gb") cData = (item.currentData / 1024 / 1024 / 1024).toFixed(2);
    if (item.currBP.billingUnit === "Mb") cData = (item.currentData / 1024 / 1024).toFixed(2);
  }

  if(item.billingPlanTypeId === 1) {
    return `${cData} ${item.currBP.billingUnit}`;
  } else {
    return 'Unlimited';
  }
}

export {
  calcBalanceHelper,
  trafficBalHelper,
}