import KeycloakUserService from "../Services/KeycloakUserService";
import {useDispatch, useSelector} from "react-redux";
import {setAtAuth} from "../redux/actions";
import {Button} from "@mui/material";
import React from "react";
import logo from "../img/mesh_logo.png";

const RenderOnAuthenticated = ({ children }) => {
  const dispatch = useDispatch();
  const {isAuth} = useSelector(({account}) => account);
  const {item: accountData} = useSelector(({account}) => account);

  const authCB = (kkToken) => {
    if (kkToken) {
      if(!isAuth) dispatch(setAtAuth(true))
    } else {
      if(isAuth) setAtAuth(false)
    }
  }
  if(accountData && accountData.role === "wisp") {
    return (
      <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
          <img alt="logo" style={{ width: "200px" }} src={logo} />
          <p className="lead">It seems you are Wisp</p>
          <div className="current-plan-item">
            <Button
              className="change-paln-btn"
              variant={"contained"}
              onClick={() => {
                KeycloakUserService.doLogout();
                dispatch(setAtAuth(false));
              }}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    )
  }
  if (KeycloakUserService.isLoggedIn(authCB)) {
    return children
  }
  return null;
}

export default RenderOnAuthenticated