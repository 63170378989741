import {useDispatch, useSelector} from "react-redux";
import "./index.scss";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {changeBillingPlan, getAccountData, getBillingPlansList} from "../redux/actions";
import {confirm} from "../Components/Confirm/confirm";
import { Breadcrumb, Typography, Divider } from "antd";

const { Title, Text } = Typography;

function BillingPlan() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {items} = useSelector(({billingplan}) => billingplan);
  const {item: accountData} = useSelector(({account}) => account);

  useEffect(() => {
    if (!items) {
      dispatch(getBillingPlansList());
    }
  }, [dispatch, items]);

  const submitBillingPlan = (billingPlanId) => {
    confirm({
      confirmActionText: "Yes", // optional
      cancelActionText: "Cancel", // optional
      cancelAction: () => {}, // optional
      confirmAction: () => {
        dispatch(changeBillingPlan({ body: { billingPlanId }}))
          .then(() => {
            dispatch(getAccountData());
            history.push("/")
          }).catch(e => console.log(e.message))
      },
      text: "Are you sure to change billing plan?", // optional
    });
  }

  if (!accountData) return null;

  const isCurrent = (id) => {
    return accountData.billingPlanId === id;
  }
  const isNext = (id) => {
    // console.log('accountdata: ', accountData);
    const index = accountData.billings ? -1 : accountData.billings.findIndex(item => item.billingPlanId === id && item.status === 1);
    if (index !== -1 && accountData.billings[index].billingPlanId !== accountData.billingPlanId) return true;
  }

  return (
    <div>
      <Breadcrumb
        style={{
          marginTop: 20,
          fontSize: '1rem',
        }}
        items={[
          {
            title: 'Dashboard',
          },
          {
            title: 'Billing Plans',
          },
        ]}
      />
      <div>
        <Title level={2} style={{ marginBottom: 5 }}>Billing Plans</Title>
        <Text type="secondary" style={{ fontSize: 16 }}>
          Select a billing plan from the options below by clicking on it then click on 'yes' on the pop up to confirm your selection.
        </Text>
      </div>
      <Divider />
      <div className="billing-plan-list">
        {items && accountData && items.map(item => (
          <div
            key={item.id}
            onClick={
            accountData.billingPlanId === item.id
              ? () => {}
              : () => submitBillingPlan(item.id)
            }
            className={
            `billing-plan-item
             ${isCurrent(item.id) ? "active" : ""}
             ${isNext(item.id) ? "next-bp" : ""}
             `}
          >
            {isNext(item.id) ? (
              <div className="next-bp-block">
                <span className="scheduled">Scheduled</span>
                <span onClick={(e) => {
                  e.stopPropagation();
                  submitBillingPlan(accountData.billingPlanId);
                }} className="cancel">Cancel</span>
              </div>
            ) : null}
            <p className='plan-header'>{item.name}</p>
            <div className='plan-content'>
              <div className='plan-items'>
                <p>Limit: {item.billingPlanTypeId === 1 ? item.dataLimit : item.timeLimit} {item.billingUnit}</p>
                <p>BandwidthUp: {item.planBandwidthUp} Mbps</p>
                <p>BandwidthDown: {item.planBandwidthDown} Mbps</p>
                {item.specOffers !== "" && (
                  <p>Special Offer: {item.specOffers}</p>
                )}
              </div>
              <p className='plan-cost'>${item.cost}<span>/{item.billingUnit}</span></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BillingPlan;