import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {saveAccountData} from "../redux/actions";
import { Button, Form, Input } from 'antd';

const AccountForm = ({ data }) => {
  const { isFetching: loading } = useSelector(({account}) => account);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    // console.log('Success:', values);
    const newData = {
      ...data,
      firstName: values.firstName,
      lastName: values.lastName,
      address: values.address,
      mobilePhone: values.mobilePhone,
    }
    // console.log("newData: ", newData);
    dispatch(saveAccountData({ body: newData }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // console.log('data: ', data);

  return (
    <div className="accountForm">
      <Form
        name="basic"
        style={{
          minWidth: 900,
          paddingTop: 50,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="First Name"
          name="firstName"
          initialValue={data.firstName}
          rules={[
            {
              required: true,
              message: 'Please input your first name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          initialValue={data.lastName}
          rules={[
            {
              required: true,
              message: 'Please input your last name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          label="Address"
          name="address"
          initialValue={data.address}
          rules={[
            {
              required: true,
              message: 'Please input your address!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          label="Mobile Phone"
          name="mobilePhone"
          initialValue={data.mobilePhone}
          rules={[
            {
              required: true,
              message: 'Please input your mobile phone number!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          label="Payment Method"
          name="payMethod"
          initialValue={data.payMethod}
          rules={[
            {
              message: 'Please input your payment method!',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        
        <Form.Item
          label="Payment Account"
          name="paymentAccount"
          initialValue={data.paymentAccount}
          rules={[
            {
              message: 'Please input your payment account!',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        
        <Form.Item
          label="Email"
          name="email"
          initialValue={data.email}
          rules={[
            {
              message: 'Please input your email!',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccountForm;