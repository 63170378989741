import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTransactionsList} from "../redux/actions";
import TransactionsTable from "./Components/TransactionsTable";
import { Breadcrumb, Typography, Divider } from 'antd';

const { Title, Text } = Typography;

function Transactions() {
  const dispatch = useDispatch();
  const { items } = useSelector(({transaction}) => transaction);

  useEffect(() => {
    dispatch(getTransactionsList());
  }, [dispatch]);

  return (
    <div>
      <Breadcrumb
        style={{
          marginTop: 20,
          fontSize: '1rem',
        }}
        items={[
          {
            title: 'Dashboard',
          },
          {
            title: 'Payments',
          },
        ]}
      />
      <div>
        <Title level={2} style={{ marginBottom: 5 }}>My Payments</Title>
        <Text type="secondary" style={{ fontSize: 16 }}>
          View your most recent payments on the below table. Check the amounts paid on different dates.
        </Text>
      </div>
      <Divider />
      <TransactionsTable items={items} />
    </div>
  );
}

export default Transactions;